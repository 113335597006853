body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 430px) {
body::-webkit-scrollbar {
  display: none;
}
}

code {
  font-family: "Poppins", sans-serif;
}

:root {
  /* Blue */
  --blue: #276dff;
  --blue-second: #2b6eff;
  --blue-secondary: #296DFF;
  --blue-third: #589bff;
  --blue-fourth: #5187ff;
  --blue-fifth: #2a6dff;
  --blue-sixth: #1c68ff;
  --blue-seventh: #2c73ff;
  --dark-blue: #043e63;
  --dark-blue-second: #0b3e63;

  /* Red */
  --red: #ff0000;

  /* White */
  --white: #ffffff;
  --white-second: #2b6eff00;
  --white-third: #00000000;
  --white-fourth: #ffffff5c;
  --white-fifth: #589bff00;
  --white-sixth: #ffffff29;
  --white-seventh: #ffffff40;

  /* Green */
  --green: #06f2ce;
  --green2: #04F2CE;
  --green-second: #06f2ce5c;
  --green-third: #00ffd4;
  --green-fourth: #84e9d7;

  /* Gray */
  --gray: #00000029;
  --gray-second: #707070;
  --gray-secondary: #919191;
  --gray-third: #dfdede;
  --gray-fourth: #00000054;
  --gray-light: #f4f4f4;
  --dark-gray: #30404d;

  /* Dark */
  --dark: #262626;

  --light: #ffffff;
  --light-2: #e8e8e8;
}

.ColorIcon:hover {
  svg {
    path {
      transition: .2s;
      fill: var(--blue);
    }  
  }
}