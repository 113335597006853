@font-face {
  font-family: "Poppins Thin";
  src: url("./Poppins-Thin.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins Light";
  src: url("./Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins ExtraBold";
  src: url("Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
